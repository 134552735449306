import React, { ReactElement } from 'react'
import './Main.scss'
import Layout from '../../components/Layout/Layout'
import Hero from '../../components/HomePage/Hero/Hero'
import Destinations from '../../components/HomePage/Destinations/Destinations'
import NewsAndEvents from '../../components/HomePage/NewsAndEvents/NewsAndEvents'

const Main = (): ReactElement => {
  return (
    <Layout>
      <Hero />
      <Destinations />
      <NewsAndEvents />
    </Layout>
  )
}

export default Main
