import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg {...props} width={980} height={885} viewBox="0 0 980 885">
      <title>{'Combined Shape'}</title>
      <path
        d="M122.72-8l183.4 338.17 29.995-55.45L182.75-8h125.59l90.025 169.085L491.741-8l91.154 169.085L675.14-8h125.59L369.986 780.082H738.4L800.73 893.8H182.79l337.613-619.28-29.43-56.616-367.728 675.896H-4l248.193-450.92L-4-8h126.72zm860.563 0L739.328 442.88l243.955 450.919H861.326L675.18 553.898 589.274 715.25H463.199L862.052-8h121.23z"
        stroke="#E1E1E1"
        strokeWidth={0.5}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgComponent
