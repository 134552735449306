import { Link, graphql, useStaticQuery } from 'gatsby'
import React, { ReactElement, useState } from 'react'
import Slider from 'react-slick'

import LeftArrow from '../../../assets/icons/LeftArrow'
import RightArrow from '../../../assets/icons/RightArrow'
import DiscoverArrow from '../../../assets/icons/DiscoverArrow'
import NoData from '../../NoData'
import './Destinations.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Destinations = (): ReactElement => {
  const destinations = useStaticQuery(graphql`
    query homeFeaturedDestinations {
      allSitePage(filter: { context: { category: { eq: "destinations" }, data: { feature: { eq: true } } } }) {
        edges {
          node {
            path
            context {
              data {
                estate
                description
                title
                updated
                featuredMedia {
                  media
                  mediaType
                }
              }
            }
          }
        }
      }
    }
  `)

  const [carousel, setCarousel] = useState<null | any>(null)

  const next = (): void => {
    if (carousel) {
      carousel.slickNext()
    }
  }

  const prev = (): void => {
    if (carousel) {
      carousel.slickPrev()
    }
  }

  const settings = {
    className: 'notes-slider',
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1.5,
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          variableWidth: true
        }
      }
    ]
  }

  return (
    <div className="destinations">
      <div className="destinations-left">
        <div className="destinations-content-container">
          <div className="destinations-content-title">
            <p>Destinations</p>
            <div className="destinations-content-title-line" />
          </div>
          <div className="destinations-content-sub-header">
            <h1>Reaching areas with potential for growth.</h1>
            <p>
              Diverse residential and commercial offerings on which thriving, sustainable communities emerge, spurring new centers of growth
              across the country.
            </p>
          </div>
          <div className="destinations-content-discover-more">
            <Link to="/destinations">Discover More</Link>
          </div>
        </div>

        {destinations.allSitePage.edges.length > 1 && (
          <div className="destinations-carousel-button-container">
            <div className="left">
              <div className="button" onClick={next}>
                <LeftArrow />
              </div>
            </div>

            <div className="button" onClick={prev}>
              <RightArrow />
            </div>
          </div>
        )}
      </div>

      <div className="destinations-right">
        {destinations.allSitePage.edges.length === 1 ? (
          <div className="notes-slider">
            <div className="destination-featured-container">
              <div className="destination-featured-info single">
                <div className="destination-title">
                  <p>{destinations.allSitePage.edges[0].node.context.data.title}</p>
                  {/* <h5>NUVALI</h5> */}
                </div>

                <Link to={destinations.allSitePage.edges[0].node.path}>
                  <div className="destination-button">
                    <p>DISCOVER</p>
                    <DiscoverArrow />
                  </div>
                </Link>
              </div>
              <div className="img-filter" />
              <img src={destinations.allSitePage.edges[0].node.context.data.featuredMedia[0].media} />
            </div>
          </div>
        ) : destinations.allSitePage.edges.length > 1 ? (
          <Slider ref={(c) => setCarousel(c)} {...settings}>
            {destinations.allSitePage.edges.map((res: any, index: number) => {
              const { node } = res
              const { path, context } = node

              let destination = context.data

              // if (destination.feature) {
              return (
                <div data-index={index} key={index} className="destination-featured-container">
                  <div className="destination-featured-info">
                    <div className="destination-title">
                      <p>{destination.title}</p>
                      {/* <h5>NUVALI</h5> */}
                    </div>

                    <Link to={path} state={{ id: destination.id }}>
                      <div className="destination-button">
                        <p>DISCOVER</p>
                        <DiscoverArrow />
                      </div>
                    </Link>
                  </div>
                  <div className="img-filter" />
                  <img src={destination.featuredMedia[0].media} />
                </div>
              )
              // }
            })}
          </Slider>
        ) : (
          <div className="no-data">
            <NoData withBackground={false} title="destinations" />
          </div>
        )}
      </div>
      {destinations.allSitePage.edges.length > 1 && (
        <div className="destinations-carousel-button-container">
          <div className="left">
            <div className="button" onClick={next}>
              <LeftArrow />
            </div>
          </div>

          <div className="button" onClick={prev}>
            <RightArrow />
          </div>
        </div>
      )}
    </div>
  )
}

export default Destinations
