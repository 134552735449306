import React, { ReactElement } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import './Layout.scss'
import './Animations.scss'
import SEO from '../SEO/SEO'

type LayoutPropTypes = {
  children?: string | Element | ReactElement | boolean | (string | Element | ReactElement | boolean)[]
}

const Layout = ({ children }: LayoutPropTypes): ReactElement => (
  <>
    <SEO title="HOME | Ayala Land International Sales" />
    <Header />
    {children}
    <Footer />
  </>
)

export default Layout
