import { PropertyProps } from './data'

export function propertyMapping(array: any): any[] {
  const newArray: any = []
  array.map((res: any) => {
    let { details } = res.node.context.property
    const { images } = res.node.context.property.overview
    const { path } = res.node

    details = {
      ...details,
      images,
      path
    }

    newArray.push(details)
  })

  return newArray
}

export const getHighestOrLowest = (identifier: 'highest' | 'lowest', items: PropertyProps[]) => {
  return items
    .map(({ priceRange }) => parseFloat(priceRange[identifier].replace(/,/g, '')))
    .filter(Boolean)
    .sort((a, b) => (identifier === 'highest' ? b - a : a - b))
}

export const equalsCheck = (a: Array<number>, b: Array<number>) => {
  return JSON.stringify(a) === JSON.stringify(b)
}
