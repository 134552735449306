import { Link } from 'gatsby'
import Slider from 'react-slick'
import React, { useState } from 'react'

import placeholderImage from '../../../assets/images/des-2.png'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './PropertyCarousel.scss'

const PropertyCarousel = ({ data }: any): JSX.Element => {
  const [carousel, setCarousel] = useState<null | any>(null)

  const next = (): void => {
    if (carousel) {
      carousel.slickNext()
    }
  }

  const prev = (): void => {
    if (carousel) {
      carousel.slickPrev()
    }
  }

  const slides = data ? data : []

  const settings = {
    className: 'properties-slider',
    dots: false,
    infinite: true,
    centerMode: false,
    initialSlide: slides.length > 3 ? 1.5 : 1,
    slidesToShow: slides.length > 3 ? 3.5 : slides.length,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slides.length > 3 ? 3.5 : slides.length,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const MockSlides = (): JSX.Element => {
    return <></>
  }

  return (
    <>
      <div className="hero-arrows fade-in dl-8">
        <div className="arrow-1" onClick={next}>
          <i className="la la-angle-left la-2x" />
        </div>
        <div onClick={prev}>
          <i className="la la-angle-right la-2x" />
        </div>
      </div>

      <div className="hero-slider fade-in dl-6">
        <Slider ref={(c) => setCarousel(c)} {...settings}>
          {data ? (
            data?.map((item: any, index: number) => {
              return (
                <div className="property-slide" key={index}>
                  <Link to={item.path}>
                    <div className="slide-content">
                    <img src={item.images.length !== 0 ? item.images[0] : placeholderImage} alt="featured properties" />
                      <div className="slide-text">
                        <div>
                          <small>{item.propertyType}</small>
                          <span>{item.name}</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })
          ) : (
            <MockSlides />
          )}
        </Slider>
      </div>
    </>
  )
}

export default PropertyCarousel
