import { Link, graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'

import './Hero.scss'
import GridLogo from '../../GridLogo/GridLogo'
import PropertyCarousel from './PropertyCarousel'
import { propertyMapping } from '../../PropertiesSearchPage/propertiesMapper'
import placeholderImage from '../../../assets/images/des-2.png'

import alisiLogo from '../../../assets/svg/alp.svg'
import alveoLogo from '../../../assets/images/brands/Alveo.png'
import avidaLogo from '../../../assets/images/brands/Avida.png'
import amaiaLogo from '../../../assets/images/brands/Amaia.png'
import bellavitaLogo from '../../../assets/images/brands/BellaVita.png'
import alpLogo from '../../../assets/images/brands/alp.png'
import diamondArrow from '../../../assets/svg/diamond-arrow.svg'

const Hero = (): JSX.Element => {
  const HomeHeroData = useStaticQuery(graphql`
    query heroBanner {
      heroBanner(title: { eq: "Homepage" }) {
        image
        description
        title
        video_link
      }

      allSitePage(
        filter: {
          context: { brand: {}, type: { eq: "overview" }, category: { eq: "property" }, property: { details: { featured: { eq: true } } } }
        }
      ) {
        edges {
          node {
            context {
              property {
                details {
                  brand
                  featured
                  floorArea
                  id
                  imageLogo
                  location
                  lotArea
                  name
                  price
                  priceRange {
                    highest
                    lowest
                  }
                  propertyType
                  slug
                  terrain
                }
                overview {
                  images
                }
              }
            }
            path
          }
        }
      }
    }
  `)

  const isImage = (src: any): boolean => {
    const regEx = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i
    return regEx.test(src)
  }

  const properties = propertyMapping(HomeHeroData.allSitePage.edges)
  const heroTagLine = HomeHeroData.heroBanner ? HomeHeroData.heroBanner.description : ''
  const alisiContentSrc = HomeHeroData.heroBanner ? HomeHeroData.heroBanner.image : ''
  const alisiContentType = HomeHeroData.heroBanner ? (isImage(HomeHeroData.heroBanner.image) ? 'image' : 'video') : 'image'

  // parsed brand properties
  const alpProperties = properties.filter((x: any) => x.brand === 'alp')
  const alveoProperties = properties.filter((x: any) => x.brand === 'alveo')
  const avidaProperties = properties.filter((x: any) => x.brand === 'avida')
  const amaiaProperties = properties.filter((x: any) => x.brand === 'amaia')
  const bellavitaProperties = properties.filter((x: any) => x.brand === 'bellavita')

  const brandBannerImage = (propertyData: any): string => {
    let bannerImage = placeholderImage

    if (propertyData.length !== 0 && propertyData[0].images.length > 0) {
      bannerImage = propertyData[0].images[0]
    }

    return bannerImage
  }

  const heroData = [
    {
      brand: 'alisi',
      contentSrc: alisiContentSrc,
      contentType: alisiContentType,
      brandDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae ornare neque, sit amet luctus nisl.',
      propertyURL: '/properties',
      homeBrand: true
    },
    {
      brand: 'alp',
      contentSrc: brandBannerImage(alpProperties),
      contentType: 'image',
      brandDescription: 'A Distinctive Living Experience',
      propertyURL: '/properties/ayala-land-premier',
      homeBrand: false,
      theme: '#707070',
      properties: alpProperties
    },
    {
      brand: 'alveo',
      contentSrc: brandBannerImage(alveoProperties),
      contentType: 'image',
      brandDescription: 'Innovating the Way You Live',
      propertyURL: '/properties/alveo',
      homeBrand: false,
      theme: '#1982B9',
      properties: alveoProperties
    },
    {
      brand: 'avida',
      contentSrc: brandBannerImage(avidaProperties),
      contentType: 'image',
      brandDescription: 'Affordable Living at its Best',
      propertyURL: '/properties/avida',
      homeBrand: false,
      theme: '#990D15',
      properties: avidaProperties
    },
    {
      brand: 'amaia',
      contentSrc: brandBannerImage(amaiaProperties),
      contentType: 'image',
      brandDescription: 'Kaya ko na!',
      propertyURL: '/properties/amaia',
      homeBrand: false,
      theme: '#297922',
      properties: amaiaProperties
    },
    {
      brand: 'bellavita',
      contentSrc: brandBannerImage(bellavitaProperties),
      contentType: 'image',
      brandDescription: 'Bahay Saya, Buhay Sagana',
      propertyURL: '/properties/bellavita',
      homeBrand: false,
      theme: '#6C731A',
      properties: bellavitaProperties
    }
  ]

  const [activeBrand, setActiveBrand] = useState('')
  const [currentContent, setCurrentContent] = useState({ brand: 'avida', type: 'image' })
  const [hoverState, setHoverState] = useState(false)
  const [brandIndex, setIndex] = useState(0)

  const closeBrandModal = (): void => {
    const closeBtn: any = document.getElementById('hero-brand-close-btn')
    if (closeBtn) {
      closeBtn.click()
    }
  }

  const mobileControlNext = (): void => {
    closeBrandModal()
    const brands: any = document.querySelectorAll('.brand')
    const brandLogos: any = document.querySelectorAll('.bx-logo')

    if (brands[brandIndex] && brandIndex < 5) {
      brands[brandIndex].style.display = 'none'
      brands[brandIndex + 1].style.display = 'grid'
      brandLogos[brandIndex + 1].click()
      setIndex(brandIndex + 1)
    } else {
      brands[5].style.display = 'none'
      brands[0].style.display = 'grid'
      setIndex(0)
    }
  }

  const mobileControlPrev = (): void => {
    closeBrandModal()
    const brands: any = document.querySelectorAll('.brand')
    const brandLogos: any = document.querySelectorAll('.bx-logo')

    if (brands[brandIndex] && brandIndex > 0) {
      brands[brandIndex].style.display = 'none'
      brands[brandIndex - 1].style.display = 'grid'
      brandLogos[brandIndex - 1].click()
      setIndex(brandIndex - 1)
    } else {
      brands[0].style.display = 'none'
      brands[5].style.display = 'grid'
      setIndex(5)
    }
  }

  const hoverFocus = (brand: string, type: string, homeBrand: boolean): void => {
    if (currentContent.brand !== brand) {
      const contentEL = document.querySelector(`#content-${type}-${brand}`) as HTMLElement
      const prevContentEL = document.querySelector(`#content-${currentContent.type}-${currentContent.brand}`) as HTMLElement

      if (currentContent.type === 'video') {
        const prevMedia = document.querySelector(`#content-${currentContent.type}-${currentContent.brand}`) as HTMLVideoElement
        prevMedia.pause()
      }

      if (type === 'video') {
        const currentMedia = document.querySelector(`#content-${type}-${brand}`) as HTMLVideoElement
        currentMedia.play()
      }

      if (prevContentEL) {
        prevContentEL.style.display = 'none'
      }

      if (contentEL) {
        contentEL.style.display = 'block'
      }

      const currentBrand = document.querySelector(`#brand-logo-${currentContent.brand}`) as HTMLElement
      const selectedBrand = document.querySelector(`#brand-logo-${brand}`) as HTMLElement

      currentBrand?.classList.remove('brand-focus')
      selectedBrand?.classList.add('brand-focus')

      setCurrentContent({ brand: brand, type: type })
      setHoverState(true)
    }
  }

  const selectBrand = (brand: string, type: string, homeBrand: boolean): void => {
    if (!homeBrand) {
      setActiveBrand(brand)
    } else {
      setActiveBrand('')
    }
  }

  useEffect(() => {
    hoverFocus('alisi', 'image', true)
  }, [])

  const fetchLogo = (brand: string): JSX.Element => {
    let logo

    switch (brand) {
      case 'alisi':
        logo = alisiLogo
        break
      case 'alveo':
        logo = alveoLogo
        break
      case 'avida':
        logo = avidaLogo
        break
      case 'amaia':
        logo = amaiaLogo
        break
      case 'bellavita':
        logo = bellavitaLogo
        break
      case 'alp':
        logo = alpLogo
        break
      default:
        logo = alisiLogo
    }

    return <img src={logo} className={`${brand}-logo fade-in-bottom dl-6`} alt="Ayala Land Brand" />
  }

  const fetchNavLogo = ({ brand, contentType, homeBrand }: any): JSX.Element => {
    let navLogo

    const handleEnter = (): void => {
      hoverFocus(brand, contentType, homeBrand)
    }
    const handleClick = (): void => {
      selectBrand(brand, contentType, homeBrand)
    }

    switch (brand) {
      case 'alisi':
        navLogo = alisiLogo
        break
      case 'alveo':
        navLogo = alveoLogo
        break
      case 'avida':
        navLogo = avidaLogo
        break
      case 'amaia':
        navLogo = amaiaLogo
        break
      case 'bellavita':
        navLogo = bellavitaLogo
        break
      case 'alp':
        navLogo = alpLogo
        break
      default:
        navLogo = alisiLogo
    }

    return (
      <img src={navLogo} className={`logo ${brand}-logo bx-logo`} alt="Ayala Land Brand" onMouseEnter={handleEnter} onClick={handleClick} />
    )
  }

  return (
    <>
      <div className="hero-banner" />

      {heroData &&
        heroData?.map((item: any, i: number) => {
          return item.contentType === 'image' ? (
            <div
              key={i}
              id={`content-image-${item.brand}`}
              className="hero-media bg-pan-right"
              style={{
                background: `url('${item.contentSrc}') center no-repeat`,
                backgroundSize: '120%'
              }}
            />
          ) : (
            <video key={i} id={`content-video-${item.brand}`} className="hero-media-video fade-in" autoPlay muted loop>
              <source src={item.contentSrc} type="video/mp4" />
            </video>
          )
        })}

      <div className="hero-mask" />
      <div className="hero-grid">
        <GridLogo />
      </div>
      <div className="hero-header">
        <div className="hero-header-content">
          <h1 className="fade-in-bottom">{heroTagLine}</h1>
          <p className="fade-in-bottom dl-4" />
        </div>

        <div className="brands-banner">
          <div className="line" />
          <span>Discover our Brands</span>
          <div className="line" />
        </div>
      </div>

      <div className={`hero-brands ${activeBrand !== '' ? 'brand-active' : ''}`}>
        {activeBrand === '' && (
          <Link to="/properties">
            <p className="journey-cta fade-in-bottom dl-3">
              Start your Journey <i className="la la-arrow-right" />
            </p>
          </Link>
        )}

        {activeBrand !== '' && (
          <div
            className="close-brand fade-in-bottom dl-9"
            id="hero-brand-close-btn"
            onClick={() => {
              setActiveBrand('')
            }}
          >
            <i className="la la-close" />
          </div>
        )}

        <div className="hero-logos">
          {heroData &&
            heroData?.map((item: any, i: number) => {
              return (
                <div
                  className={`brand ${item.homeBrand ? 'brand-focus' : ''} ${activeBrand === item.brand ? 'active' : ''}`}
                  key={i}
                  id={`brand-logo-${item.brand}`}
                  style={activeBrand === item.brand ? { background: item.theme, transition: '0.3s' } : {}}
                >
                  <div className="brand-controls">
                    <div />

                    <PropertyCarousel data={item.properties} brand={item.brand} />

                    <div className="brand-description">
                      {fetchLogo(item.brand)}
                      <p className="fade-in-bottom dl-4">{item.brandDescription}</p>
                      <Link to={item.propertyURL}>
                        <img src={diamondArrow} className="to-properties fade-in-bottom dl-6" alt="Featured property button" />
                      </Link>
                    </div>
                  </div>
                  {fetchNavLogo(item)}
                </div>
              )
            })}
        </div>

        <div className="mobile-hero-control left fade-in" onClick={mobileControlPrev}>
          <i className="la la-angle-left la-2x" />
        </div>

        <div className="mobile-hero-control right fade-in" onClick={mobileControlNext}>
          <i className="la la-angle-right la-2x" />
        </div>
      </div>
    </>
  )
}

export default Hero
