import React from 'react'
import './NoData.scss'
import Logo from '../Logo/Logo'
import BgProperties from '../../assets/icons/BgProperties'

type NoDataTypes = {
  withBackground: boolean
  title: string
}

const NoData = ({ withBackground, title }: NoDataTypes) => {
  return (
    <>
      <div className="no-data-container">
        <div className="no-result">
          <Logo fill="#D8D8D8" size={70} />
          <span>No {title} posted yet</span>
        </div>
      </div>
      {withBackground ? (
        <div className="properties-background">
          <BgProperties />
        </div>
      ) : null}
    </>
  )
}

export default NoData
