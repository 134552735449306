import { Link, navigate, graphql, useStaticQuery } from 'gatsby'
import React, { ReactElement, useState } from 'react'
import Slider from 'react-slick'

import './NewsAndEvents.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import LeftArrow from '../../../assets/icons/LeftArrow'
import RightArrow from '../../../assets/icons/RightArrow'
import NoData from '../../NoData'

const NewsAndEvents = (): ReactElement => {
  const newsAndEvents = useStaticQuery(graphql`
    query homeFeaturedNewsAndEvents {
      allSitePage(filter: { context: { category: { eq: "news-and-events" }, data: { feature: { eq: true } } } }) {
        edges {
          node {
            path
            context {
              data {
                estate
                description
                title
                updated
                featuredMedia {
                  media
                  mediaType
                }
              }
            }
          }
        }
      }
    }
  `)

  const [carousel, setCarousel] = useState<null | any>(null)
  const [mouse, setMouse] = useState<any>({})

  const next = (): void => {
    if (carousel) {
      carousel.slickNext()
    }
  }

  const prev = (): void => {
    if (carousel) {
      carousel.slickPrev()
    }
  }

  const settings = {
    className: 'notes-slider',
    dots: false,
    infinite: true,
    centerMode: false,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          variableWidth: true
        }
      }
    ]
  }

  const handleMouseDown = (event: any): void => {
    event.preventDefault()
    setMouse({
      x: event.pageX,
      y: event.pageY
    })
  }

  const handleMouseUp = (event: any, link: string): void => {
    if (mouse.x === event.pageX && mouse.y === event.pageY) {
      navigate(link)
      setMouse({})
    }
  }

  return (
    <div className="news-and-events">
      <div className="news-and-events-title-container">
        <div className="news-and-events-title">
          <p>News and Events</p>
          <div className="news-and-events-title-line" />
        </div>
      </div>

      <div className="news-and-events-content-sub-header">
        <h1>Learn more about Ayala Land's current projects and initiatives.</h1>
      </div>

      <div className="news-and-events-content-view-all">
        <Link to="/news-and-events">View All Articles</Link>
      </div>

      <div className="news-and-events-carousel-container">
        {newsAndEvents.allSitePage.edges.length > 0 ? (
          <Slider ref={(c) => setCarousel(c)} {...settings}>
            {newsAndEvents.allSitePage.edges.map((res: any, index: number) => {
              const { node } = res
              const { path, context, mediaImages } = node
              const article = context.data

              return (
                <div
                  key={index}
                  className="news-and-events-featured-container"
                  onMouseDown={(event) => handleMouseDown(event)}
                  onMouseUp={(event) => handleMouseUp(event, path)}
                >
                  <img src={article.featuredMedia[0].media} />
                  <h5>{article.title}</h5>
                  <p>{article.updated}</p>
                </div>
              )
            })}
          </Slider>
        ) : (
          <div className="no-data">
            <NoData withBackground={false} title="news and events" />
          </div>
        )}
      </div>

      {newsAndEvents.allSitePage.edges.length > 4 && (
        <div className="news-and-events-carousel-button-container">
          <div className="left">
            <div className="button" onClick={() => next()}>
              <LeftArrow />
            </div>
          </div>

          <div className="button" onClick={() => prev()}>
            <RightArrow />
          </div>
        </div>
      )}
    </div>
  )
}

export default NewsAndEvents
